var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('h2', {
    staticClass: "join-title"
  }, [_vm._v("회원가입")]), _c('div', {
    staticClass: "join-container"
  }, [_c('nav', {
    staticClass: "join-step"
  }, [_c('ul', {
    staticClass: "join-step-lists"
  }, [_c('li', {
    staticClass: "list list--on"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("STEP 1")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("약관동의")])]), _c('li', {
    staticClass: "list list--on"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("STEP 2")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("정보입력")])]), _c('li', {
    staticClass: "list"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("STEP 3")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("가입완료")])])])]), _c('join-form', {
    attrs: {
      "code": _vm.code,
      "skin": _vm.code
    }
  })], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }